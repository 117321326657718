import { configureStore } from "@reduxjs/toolkit";
import reducers from "../reducers";
import { setupListeners } from "@reduxjs/toolkit/query";
import { masterData } from "../../api/services/master";
import { homeData } from "../../api/services/home";
import { coursesData } from "../../api/services/courses";
import { aboutUsData } from "../../api/services/aboutus";
import { contactUsData } from "../../api/services/contactus";
import { topRankersData } from "../../api/services/topRankers";
import { ednoFestData } from "../../api/services/ednofest";
import { ordersData } from "../../api/services/orders";
import { cartData } from "../../api/services/cart";
import { workWithUsData } from "../../api/services/workWithUs";
import { enquireData } from "../../api/services/enquire";
import { authData } from "../../api/services/auth";
import { paymentData } from "../../api/services/payment";
import { mahaRevisionData } from "../../api/services/mahaRevision";

const store = configureStore({
  reducer: {
    app: reducers,
    // Add the generated reducer as a specific top-level slice
    [masterData.reducerPath]: masterData.reducer,
    [homeData.reducerPath]: homeData.reducer,
    [coursesData.reducerPath]: coursesData.reducer,
    [aboutUsData.reducerPath]: aboutUsData.reducer,
    [contactUsData.reducerPath]: contactUsData.reducer,
    [topRankersData.reducerPath]: topRankersData.reducer,
    [ednoFestData.reducerPath]: ednoFestData.reducer,
    [ordersData.reducerPath]: ordersData.reducer,
    [cartData.reducerPath]: cartData.reducer,
    [workWithUsData.reducerPath]: workWithUsData.reducer,
    [enquireData.reducerPath]: enquireData.reducer,
    [authData.reducerPath]: authData.reducer,
    [paymentData.reducerPath]: paymentData.reducer,
    [mahaRevisionData.reducerPath]: mahaRevisionData.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(masterData.middleware)
      .concat(homeData.middleware)
      .concat(coursesData.middleware)
      .concat(aboutUsData.middleware)
      .concat(contactUsData.middleware)
      .concat(topRankersData.middleware)
      .concat(ednoFestData.middleware)
      .concat(cartData.middleware)
      .concat(ordersData.middleware)
      .concat(workWithUsData.middleware)
      .concat(enquireData.middleware)
      .concat(authData.middleware)
      .concat(paymentData.middleware)
      .concat(mahaRevisionData.middleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
