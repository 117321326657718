import {
  ADD_TO_CART,
  DELETE_FROM_CART,
  GET_CART_DETAILS,
} from "../../constants/cart";
// const courseCardArr = [
//   {
//     id: 1,
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge 1",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     id: 2,
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge 2",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     id: 3,
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge 3",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
//   {
//     id: 4,
//     thumbnail: "default-placeholder/course-default2.jpg",
//     title: "Business and Commercial Knowledge 4 ",
//     catergory: "SYJC",
//     rating: "4.9",
//     proposerThumb: "",
//     proposerName: "Prof. Khyati Shah",
//     publishDate: "17 Dec 2023",
//     enrolledCount: "15000",
//     courseDiscountPrice: "15,000",
//     coursePrice: "18,000",
//   },
// ];
const home = (
  state = {
    cartItems: [],
  },
  action
) => {
  switch (action.type) {
    case GET_CART_DETAILS:
      return {
        ...state,
        cartItems: action.payload,
      };
    case DELETE_FROM_CART:
      const deleteIndex = state.cartItems.findIndex(
        (x) => x.id === action.payload
      );

      return {
        ...state,
        cartItems: [
          ...state.cartItems.slice(0, deleteIndex),
          ...state.cartItems.slice(deleteIndex + 1),
        ],
      };
    case ADD_TO_CART:
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    default:
      return state;
  }
};

export default home;
