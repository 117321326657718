export const SET_ACTIVE_LEARNING_TYPE = "SET_ACTIVE_LEARNING_TYPE";
export const SET_ACTIVE_COURSE_TYPE = "SET_ACTIVE_COURSE_TYPE";
export const SET_ACTIVE_CLASS_TYPE = "SET_ACTIVE_CLASS_TYPE";
export const SET_ACTIVE_SUBJECT_TYPE = "SET_ACTIVE_SUBJECT_TYPE";
export const SET_ACTIVE_REVISION_TYPE = "SET_ACTIVE_REVISION_TYPE";
export const SET_SHOW_OUR_COMMUNITY_OVERLAY = "SET_SHOW_OUR_COMMUNITY_OVERLAY";
export const SET_SHOW_ENROLL_OVERLAY = "SET_SHOW_ENROLL_OVERLAY";
export const SET_ACTIVE_BRANCH_STATE = "SET_ACTIVE_BRANCH_STATE";
export const SET_ACTIVE_ZONE = "SET_ACTIVE_ZONE";
export const SET_SHOW_BRANCH_OVERLAY = "SET_SHOW_BRANCH_OVERLAYSET_ACTIVE_ZONE";
export const SET_SHOW_BROCHURE_OVERLAY = "SET_SHOW_BROCHURE_OVERLAY";
export const SET_SHOW_OUR_COURSES_VIDEO_OVERLAY =
  "SET_SHOW_OUR_COURSES_VIDEO_OVERLAY";
