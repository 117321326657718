import { SET_MOBILE_NUMBER } from "../../constants/auth";

const auth = (state = {}, action) => {
  switch (action.type) {
    case SET_MOBILE_NUMBER:
      return {
        ...state,
        mobileNumber: action.payload,
      };
    default:
      return state;
  }
};

export default auth;
