const routesPath = {
  home: { path: "" },
  courses: { path: "courses/:batch/:course" },
  coursesDetail: { path: "courses/:batch/:course/:id" },
  about: { path: "about-us" },
  ednoverse: { path: "ednoverse" },
  mahaRevision: { path: "maha-revision" },
  topRankers: { path: "top-rankers" },
  freeResources: { path: "free-resources" },
  contact: { path: "contact" },
  cart: { path: "cart" },
  login: { path: "login" },
  career: { path: "career" },
  workWithUs: { path: "work-with-us" },
  register: { path: "register" },
  dashboard: { path: "dashboard" },
  profile: { parent: "dashboard", path: "profile" },
  orderHistory: { parent: "dashboard", path: "order-history" },
  schedule: { parent: "dashboard", path: "schedule" },
  paymentSuccess: { path: "payment-success" },
  paymentFailure: { path: "payment-failure" },
  terms: { path: "terms-and-conditions" },
  privacy: { path: "privacy-policy" },
  refund: { path: "refund-policy" },
  enroll: { path: "enrol" },
  ourCourses: { path: "our-courses/:batch" },
  fallback: { path: "#" },
};

export default routesPath;
