import { combineReducers } from "@reduxjs/toolkit";
import home from "./home";
import cart from "./cart";
import auth from "./auth";

export default combineReducers({
  home,
  cart,
  auth,
});
